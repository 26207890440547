// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


/* ------------------------------
Loading イメージ表示関数
引数： msg 画面に表示する文言
------------------------------ */
window.dispLoading = function(msg) {
  // 引数なし（メッセージなし）を許容
  if( msg == undefined ){
    msg = "";
  }
  // 画面表示メッセージ
  var dispMsg = "<div class='loadingMsg'>" + msg + "</div>";
  // ローディング画像が表示されていない場合のみ出力
  if($("#loading").length == 0){
    $("body").append("<div id='loading'>" + dispMsg + "</div>");
  }
}
    
/* ------------------------------
Loading イメージ削除関数
------------------------------ */
window.removeLoading = function() {
  $("#loading").remove();
}
    
// トースト通知クラス
window.Toast = (function(){
  var timer;
  var speed;
  function Toast() {
    this.speed = 3000;
  }
  // メッセージを表示。表示時間(speed)はデフォルトで3秒
  Toast.prototype.show = function(message, speed) {
    if (speed === undefined) speed = this.speed;
    $('.toast').remove();
    clearTimeout(this.timer);
    $('body').append('<div class="toast">' + message + '</div>');
    var leftpos = $('body').width()/2 - $('.toast').outerWidth()/2;
    $('.toast').css('left', leftpos).hide().fadeIn('fast');
    
    this.timer = setTimeout(function() {
      $('.toast').fadeOut('slow',function(){
        $(this).remove();
        });
      }, speed);
  };
  // 明示的にメッセージを消したい場合は使う
  Toast.prototype.hide = function() {
    $('.toast').fadeOut('slow',function() {
      $(this).remove();
    });
  }
  return Toast;
})();